@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import 'ngx-drag-to-select';
@import 'normalize.css';
//@import "primeng/resources/themes/fluent-light/theme.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "@fortawesome/fontawesome-free/css/all.css";

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

th {
  //font-size: 13px;
  color: #8D93C2 !important;
  background-color: white !important;
  padding: 15px !important;
  // padding-left: 20px !important;
  border: none !important;
}

td {
  //font-size: 15px;
  padding: 10px !important;
  border: none !important;
  padding-left: 15px !important;
}

/* CSS personalizado para o hover */
.p-datatable .p-datatable-tbody > tr:hover {
  background: #92abe238;
}

// .tableStatusMonitoring .p-datatable .p-datatable-tbody > tr:hover {
//   background: none;
// }

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
}

.p-sidebar-content {
  overflow-y: clip !important;
}

.p-dropdown-item.p-ripple {
  white-space: normal;
}

// Padronizando height do input/dropdown
$default_input_height: 40px;
.p-inputtext {
  height: $default_input_height;
}

.p-dropdown {
  height: $default_input_height;
}

.background-body {
  margin: 0;
  background-color: var(--surface-ground) !important;
}

.btn-voltar__breadcrumb {
  background-color: #eff3f8 !important;
  padding: 0.75rem !important;
  font-size: 0.725rem !important;
  height: 0.25rem !important;
  width: 5rem !important;
}

// Sobrescrevendo padding do p-card para ficar mais harmonico
.p-card .p-card-content {
  padding: 0 !important;
}

// Sobrescrevendo padding do p-fieldset para ficar mais harmonico
.p-fieldset .p-fieldset-content {
  padding: 0 1.25rem 1.25rem !important;
}

.background-clean .p-dialog-content {
  background-color: #eff3f8 !important;
}

.background-clean .p-dialog-header {
  background-color: #eff3f8 !important;
}

.p-button-micro {
  height: 2rem !important;
  width: 2rem !important;
}

.selected .p-card {
  border: 2px solid #3b82f6;
}

.p-tieredmenu .p-menuitem {
  position: -webkit-sticky !important;
}

.dialog-forgot {
  .p-dialog-title {
    width: 100%;
    text-align: center;
    margin-left: 32px;
  }
}

.toastInfo {
  background-color: #e8e8e8;
  border: solid #838383;
  border-width: 0 0 0 6px;
}

//remove padding do card com accordion no strip-config
.card-on-accordion .p-card-body{
  padding: 0;
}

// removendo background do p-galleria para a triagem de infracoes
.p-galleria .p-galleria-thumbnail-container {
  background-color: transparent !important;
}

.thumbnail-triagem .p-galleria .p-galleria-thumbnail-container {
    border-radius: 0 0 4px 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    width: 50%;
    margin: auto;
}

.p-splitter {
  border: none !important;
  background: none !important;
}

.p-splitter-panel-nested {
  display: block !important;
}

.configuracao__navigation--menu {
  background: none !important;
  border: none !important;

  a {
    border-left: 1px solid var(--surface-border) !important;
    font-weight: 450 !important;
    display: flex !important;
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
    color: var(--surface-700) !important;
    transition: all .2s !important;
  }

  .p-menuitem-content:hover {
    background: #8a93c324 !important;
  }

  a.router-link-active {
    color: var(--primary-color) !important;
    border-left-color: var(--primary-color) !important;
    font-weight: 700 !important;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

// override step component
.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: #0650a8 !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #054fa7 !important;
  color: #ffffff !important;
}


.card-body-configuracao-equipamento {
  .card-config {
    min-height: calc(100vh - 295px) !important;
    max-height: calc(100vh - 295px) !important;
    overflow: auto !important;
  }
}

.p-tag.p-tag-info {
  background-color: #00b7c3;
  color: #ffffff;
}

.menu_no-border {
  .p-menu {
    border: none !important;
  }
}

.p-datepicker-trigger {
  height: 2.5rem !important;
}

.p-inputgroup-addon {
  height: 2.5rem !important;
}

.ptooltip__msg--error {
  .p-tooltip-text {
    width: 350px !important;
  }
}

#menu_header {
  position: static !important;
  border: none !important;
}

.overlay__menu-header {
  .p-overlaypanel-content {
    padding: 0 !important;
  }
}

.dropdown-borders {
  li {
    border-top: 1px #ced4da solid !important;
  }
}

.menu__reservadas {
  max-height: 400px !important;
  overflow: auto !important;
}
